import React, { useEffect, useState } from "react";
import { personalDetails } from "../Details";

function Home() {
  // Destructure these values carefully, providing fallbacks if needed
  const { name = "Robert O'Connor", img } = personalDetails;

  // Define taglines directly in the component to ensure they exist
  const taglines = [
    "Software Engineer",
    "Problem Solver",
    "Creative Thinker",
    "Technologist",
    "Builder",
  ];

  const [currentTaglineIndex, setCurrentTaglineIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [initialAnimationComplete, setInitialAnimationComplete] =
    useState(false);
  const [showCursor, setShowCursor] = useState(false);

  // Use a single state for the complete tagline
  const [greetingText, setGreetingText] = useState("");
  const [introText, setIntroText] = useState("");
  const [nameText, setNameText] = useState("");
  const [fullTaglineText, setFullTaglineText] = useState("");

  // Fixed prefix for taglines
  const PREFIX = "I'm a ";

  // Initial animations
  useEffect(() => {
    // Hard-code exact greeting text to ensure it displays correctly
    const animateGreeting = () => {
      let index = 0;
      const helloText = "Hello,";

      const greetingInterval = setInterval(() => {
        if (index < helloText.length) {
          setGreetingText(helloText.substring(0, index + 1));
          index++;
        } else {
          clearInterval(greetingInterval);
          // Add wave emoji separately after a short delay
          setTimeout(() => {
            setGreetingText("Hello, 👋");
            // Move to next animation
            animateIntro();
          }, 300);
        }
      }, 100);
    };

    const animateIntro = () => {
      let index = 0;
      const myNameIs = "my name is";

      const introInterval = setInterval(() => {
        if (index < myNameIs.length) {
          setIntroText(myNameIs.substring(0, index + 1));
          index++;
        } else {
          clearInterval(introInterval);
          // Move to name animation
          animateName();
        }
      }, 100);
    };

    const animateName = () => {
      let index = 0;

      const nameInterval = setInterval(() => {
        if (index < name.length) {
          setNameText(name.substring(0, index + 1));
          index++;
        } else {
          clearInterval(nameInterval);
          // Move to full tagline animation
          animateFullTagline();
        }
      }, 60);
    };

    const animateFullTagline = () => {
      let index = 0;
      const completeTagline = PREFIX + taglines[0];

      const taglineInterval = setInterval(() => {
        if (index < completeTagline.length) {
          setFullTaglineText(completeTagline.substring(0, index + 1));
          index++;
        } else {
          clearInterval(taglineInterval);
          // Show cursor only after all initial text is typed
          setShowCursor(true);
          setInitialAnimationComplete(true);
        }
      }, 80);
    };

    // Start the animation sequence
    animateGreeting();
  }, []);

  // Tagline cycling
  useEffect(() => {
    if (!initialAnimationComplete || isAnimating) return;

    const taglineInterval = setInterval(() => {
      setIsAnimating(true);

      // Get the current complete tagline
      let currentText = fullTaglineText;

      // Only erase the descriptor part, keeping "I'm a "
      const eraseTagline = () => {
        if (currentText.length > PREFIX.length) {
          currentText = currentText.substring(0, currentText.length - 1);
          setFullTaglineText(currentText);
          setTimeout(eraseTagline, 30);
        } else {
          // Update tagline index
          const nextIndex = (currentTaglineIndex + 1) % taglines.length;
          setCurrentTaglineIndex(nextIndex);

          // Type new tagline (but keep prefix)
          let newIndex = 0;
          const newTagline = taglines[nextIndex];

          const typeNewTagline = () => {
            if (newIndex < newTagline.length) {
              setFullTaglineText(
                PREFIX + newTagline.substring(0, newIndex + 1)
              );
              newIndex++;
              setTimeout(typeNewTagline, 80);
            } else {
              setIsAnimating(false);
            }
          };

          setTimeout(typeNewTagline, 200);
        }
      };

      eraseTagline();
    }, 4000);

    return () => clearInterval(taglineInterval);
  }, [
    initialAnimationComplete,
    isAnimating,
    currentTaglineIndex,
    fullTaglineText,
    taglines,
  ]);

  return (
    <div className="container mx-auto max-width section">
      {/* Use a grid layout with fixed positions for both sections */}
      <div className="grid grid-cols-1 md:grid-cols-2">
        {/* Left section for text - with minimum height to prevent layout shifts */}
        <div className="min-h-[250px] md:min-h-[300px] xl:min-h-[350px]">
          <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
            {greetingText}
          </h1>
          <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold mt-2">
            {introText}
          </h1>

          {/* Name with Tailwind gradient classes */}
          <h1 className="text-2xl md:text-4xl xl:text-5xl xl:leading-tight font-bold mt-2 bg-gradient-to-r from-sky-500 via-purple-500 to-pink-600 bg-clip-text text-transparent">
            {nameText}
          </h1>

          <h2 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold mt-2">
            <span className="inline-block whitespace-nowrap">
              {fullTaglineText}
            </span>
            {showCursor && <span className="typing-cursor">|</span>}
          </h2>
        </div>

        {/* Right section for logo - fixed position */}
        <div className="md:mt-0 flex justify-center md:justify-end items-center absolute md:relative top-[300px] right-0 md:top-auto md:right-auto">
          <img className="w-1/2 md:ml-auto" src={img} alt="Robert O'Connor" />
        </div>
      </div>
    </div>
  );
}

export default Home;
