import React from "react";
function Footer() {
  return (
    <footer className="container mx-auto py-1 fixed bottom-0 md:left-20 bg-white dark:bg-dark-mode">
      <p className="text-xs text-center text-dark-content dark:text-light-content w-full">
        My website is serverless, secure and sexy{" "}
        {/* <a
          className="font-medium"
          href="https://rob-oconnor.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          Rob
        </a>{" "} */}
        using
        <span className="text-gradient font-medium"> S3</span> &
        <span className="text-gradient font-medium"> Cloudfront</span>
      </p>
    </footer>
  );
}
export default Footer;
