// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.svg";
// import logo from "./assets/logo2.svg";
import robsRLogo from "./assets/robsRLogo.png";
// import me from "./assets/me.png"
// Profile Image
import profile from "./assets/profile.jpg";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import sass from "./assets/techstack/sass.png";
import go from "./assets/techstack/go.png";
import js from "./assets/techstack/js.png";
import nodejs from "./assets/techstack/nodejs.png";
import python from "./assets/techstack/python.png";
import react from "./assets/techstack/react.png";
import redux from "./assets/techstack/redux.png";
import tailwind from "./assets/techstack/tailwind.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import figma from "./assets/techstack/figma.png";
import alexa from "./assets/techstack/alexa.png";
import aws from "./assets/techstack/aws.png";
import lambda from "./assets/techstack/lambda.png";
import dynamoDB from "./assets/techstack/dynamoDB.png";
import apiGateway from "./assets/techstack/apiGateway.png";
import cloudformation from "./assets/techstack/cf.png";
import docker from "./assets/techstack/docker.png";

import awsAIPractitioner from "./assets/certifications/aws-ai-practitioner.png";
import awsSolutionsArchitect from "./assets/certifications/aws-solutions-architect.png";
import awsDeveloper from "./assets/certifications/aws-developer.png";

import projectImage4 from "./assets/projects/project4.jpg";
import projectImage5 from "./assets/projects/project5.jpg";
import projectImage6 from "./assets/projects/project6.jpg";

import nutritionDataTool from "./assets/projects/nutritionDataTool.jpg";
// import paraphrasingTool from "./assets/projects/paraphrasingTool.jpg"
import apiGatewayLambda from "./assets/projects/apiGatewayLambda.png";
import robotTyping from "./assets/projects/robotTyping.png";

// Logos
export const logos = {
  logogradient: robsRLogo, // was logogradient
  logo: robsRLogo, // was logo
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Robert O'Connor",
  taglines: ["Software engineer", "Problem solver", "Technologist"],
  img: robsRLogo, // profile pricture
  about: `Hi there, I'm an AWS Cloud Engineer and Technical Lead with expertise in serverless architecture and cloud-native solutions since 2015. I've led development teams across financial services, postal, logistics, and energy sectors at Accenture, implementing complex solutions using AWS Lambda, Step Functions, and DynamoDB. Notable projects include the Post Office Horizon Migration and Barclays Call Center Transformation. My interest in AI began while specializing in Amazon Alexa Skills development, complemented by certifications as an AWS AI Practitioner, Solutions Architect, and Developer Associate. First Class Honours BSc graduate and two-time Sky News guest discussing alternative education pathways.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/in/rob-oconnor/",
  github: "https://github.com/HealthyTechGuy",
  twitter: "https://twitter.com/",
  instagram: "https://www.instagram.com/",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Lead Developer (Golang) ",
    Company: `Accenture - Postal / logistics Client`,
    Location: "Remote",
    Type: "Full Time",
    Duration: "2022 - 2023",
  },
  {
    Position: "Lead Developer (Golang)",
    Company: `Accenture - Energy Client`,
    Location: "Remote",
    Type: "Full Time",
    Duration: "June 2022 - Oct 2022",
  },
  {
    Position: "Lead Developer (Amazon Connect Specialist)",
    Company: `Accenture - Financial Services Client (Digital Contact Center Transformation)`,
    Location: "Remote",
    Type: "Full Time",
    Duration: "2020 - 2022",
  },
  {
    Position: "Lead Developer (Stibo STEP)",
    Company: `Accenture - Wholesale Retail Logistics Data Management `,
    Location: "Remote",
    Type: "Full Time",
    Duration: "2020 - 2022",
  },
  {
    Position: "AWS Cloud Developer (Node.js & Typescript)",
    Company: `Accenture - Accenture Cloud Platform`,
    Location: "London",
    Type: "Full Time",
    Duration: "2019 - 2020",
  },
  {
    Position: "Backend Developer & Alexa SME",
    Company: `Accenture - Liquid Studio (Rapid Prototyping & Innovation Center)`,
    Location: "London",
    Type: "Full Time",
    Duration: "2018 - 2019",
  },
  {
    Position: "Software Engineering Apprenticeship",
    Company: `Accenture`,
    Location: "London",
    Type: "Full Time",
    Duration: "Sep 2015 - Sept 2019",
  },
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "AWS AI Practitioner Certification",
    Company: "Amazon Web Services",
    Location: "Online",
    Type: "Full Time",
    Duration: "2025",
    CertificationIcon: awsAIPractitioner,
  },
  {
    Position: "AWS Solutions Architect Associate Certification",
    Company: "Amazon Web Services",
    Location: "Online",
    Type: "Full Time",
    Duration: "2023",
    CertificationIcon: awsSolutionsArchitect,
  },
  {
    Position: "AWS Developer Associate Certification",
    Company: "Amazon Web Services",
    Location: "In Person",
    Type: "Full Time",
    Duration: "2018",
    CertificationIcon: awsDeveloper,
  },
  {
    Position: "Bachelor (Hons) Digital & Technology Solutions",
    Company: `University of Roehampton`,
    Location: "London",
    Type: "Full Time",
    CertificationIcon: null,
    // Duration: "Aug 2016 - 2020",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  go: go,
  nodejs: nodejs,
  python: python,
  react: react,
  redux: redux,
  sass: sass,
  tailwind: tailwind,
  bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
  alexa: alexa,
  aws: aws,
  lambda: lambda,
  dynamoDB: dynamoDB,
  apiGateway: apiGateway,
  cloudformation: cloudformation,
  docker: docker,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title:
      "I built a food nutrition data tool that uses NLP and connected it to an API",
    image: nutritionDataTool,
    description: `This leverages NLP (Natural Language Processing) to extract information such as “Food name”, “Serving size”, “Amount” from a sentence such as “One egg and 100 grams of chicken” and responds with a breakdown of over 70 nutrition metrics including vitamins, fats, amino acids, calories, and protein for that exact food amount.`,
    techstack: "HTML/CSS, React, Node.js, Lambda, API gateway",
    previewLink:
      "https://medium.com/@healthytechguy/i-built-a-food-nutrition-data-tool-that-uses-nlp-and-connected-it-to-an-api-github-link-included-8502dca431b5",
    githubLink: "https://github.com/HealthyTechGuy/foodNutritionDataTool",
  },
  {
    title: "I built a Paraphrasing Tool that can rewrite text",
    image: robotTyping,
    description: `This project will automatically correct grammatical errors and paraphrase where possible while attempting to maintain the logic of a sentence. The frontend is using React and the backend is serverless built using a Node.js lambda connected to API gateway.`,
    techstack: "React, Node.js, javascript, Lambda, API gateway",
    previewLink:
      "https://healthytechguy.medium.com/i-built-a-paraphrasing-tool-that-can-rewrite-text-and-made-it-opensource-3833e1b93c07",
    githubLink: "https://github.com/HealthyTechGuy/paraphrasingTool",
  },
  {
    title: "How to create a serverless REST API in AWS within 15 minutes",
    image: apiGatewayLambda,
    description: `This tutorial will help you build and deploy a simple serverless API with AWS Lambda & API Gateway using Express & Node.js.

    Let’s say you want to quickly build an API without having to worry about server costs or maintenance, one of the best ways to achieve this is using the free tier offered by AWS which offers very generous limits for the first 12 months, even after this, Lambda’s pricing is $0.20 per 1M requests & API Gateway is $1.00 for the first 300 million requests.`,
    techstack: "React, Node.js, Lambda, API gateway",
    previewLink:
      "https://medium.com/swlh/how-to-create-a-serverless-rest-api-in-aws-within-15-minutes-d14f6d12ae42",
    githubLink:
      "https://github.com/HealthyTechGuy/serverless-express-api-template",
  },
];

// Enter your Contact Details here
export const contactDetails = {
  email: "oconnor.rob.enquiries@gmail.com",
  phone: "+44",
};
