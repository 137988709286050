import React, { useEffect } from "react";
import { techStackDetails } from "../Details";

function Technologies() {
  const {
    html,
    css,
    go,
    js,
    nodejs,
    python,
    react,
    redux,
    tailwind,
    bootstrap,
    sass,
    vscode,
    git,
    github,
    npm,
    postman,
    figma,
    alexa,
    aws,
    lambda,
    dynamoDB,
    apiGateway,
    cloudformation,
    docker,
  } = techStackDetails;

  // Add subtle animation to tech icons when page loads
  useEffect(() => {
    const techIcons = document.querySelectorAll(".tech-icon");

    techIcons.forEach((icon, index) => {
      // Staggered fade-in effect
      setTimeout(() => {
        icon.style.opacity = "1";
        icon.style.transform = "translateY(0)";
      }, 100 * index);
    });
  }, []);

  return (
    <main className="container mx-auto max-width pt-10 pb-20">
      <section>
        <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tech Stack
        </h1>
        <p className="text-content py-2 lg:max-w-3xl">
          Technologies I've been working with recently
        </p>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <div className="tech-icon">
          <img src={go} title="Go" alt="Go" className="tech-img" />
        </div>
        <div className="tech-icon">
          <img
            src={js}
            title="JavaScript"
            alt="JavaScript"
            className="tech-img"
          />
        </div>
        <div className="tech-icon">
          <img src={python} title="Python" alt="Python" className="tech-img" />
        </div>
        <div className="tech-icon">
          <img src={nodejs} title="Nodejs" alt="Nodejs" className="tech-img" />
        </div>
        <div className="tech-icon">
          <img src={html} title="HTML" alt="HTML" className="tech-img" />
        </div>
        {/* <div className="tech-icon">
          <img src={css} title="CSS" alt="CSS" className="tech-img" />
        </div> */}
        <div className="tech-icon">
          <img src={react} title="React" alt="React" className="tech-img" />
        </div>
      </section>

      <section>
        <h1 className="text-2xl pt-10 text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tools & Services
        </h1>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <div className="tech-icon">
          <img
            src={vscode}
            title="Visual Studio Code"
            alt="Visual Studio Code"
            className="tech-img"
          />
        </div>
        <div className="tech-icon">
          <img src={git} title="Git" alt="Git" className="tech-img" />
        </div>
        <div className="tech-icon">
          <img src={github} title="Github" alt="Github" className="tech-img" />
        </div>
        <div className="tech-icon">
          <img src={npm} title="NPM" alt="NPM" className="tech-img" />
        </div>
        <div className="tech-icon">
          <img
            src={postman}
            title="Postman"
            alt="Postman"
            className="tech-img"
          />
        </div>
        <div className="tech-icon">
          <img src={docker} title="Docker" alt="Docker" className="tech-img" />
        </div>
        <div className="tech-icon">
          <img
            src={apiGateway}
            title="API Gateway"
            alt="API Gateway"
            className="tech-img"
          />
        </div>
        <div className="tech-icon">
          <img src={alexa} title="Alexa" alt="Alexa" className="tech-img" />
        </div>
        <div className="tech-icon">
          <img src={lambda} title="Lambda" alt="Lambda" className="tech-img" />
        </div>
        <div className="tech-icon">
          <img
            src={dynamoDB}
            title="DynamoDB"
            alt="DynamoDB"
            className="tech-img"
          />
        </div>
        <div className="tech-icon">
          <img
            src={cloudformation}
            title="CloudFormation"
            alt="CloudFormation"
            className="tech-img"
          />
        </div>
        <div className="tech-icon">
          <img src={aws} title="AWS" alt="AWS" className="tech-img" />
        </div>
      </section>
    </main>
  );
}

export default Technologies;
