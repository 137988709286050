import React from "react";
import { contactDetails } from "../Details";

function Contact() {
  const { email, phone } = contactDetails;
  return (
    <main className="container mx-auto max-width section">
      <h1 className="text-center text-2xl md:text-3xl lg:text-5xl text-dark-heading dark:text-light-heading font-semibold md:font-bold">
        To reach me please send an email to:
      </h1>

      {/* Email with reduced font size to keep on one line */}
      <div className="text-center pt-5 md:pt-10 md:pb-6 overflow-hidden">
        <a
          href={`mailto:${email}`}
          className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-gradient font-semibold md:font-bold whitespace-nowrap inline-block"
        >
          {email}
        </a>
      </div>

      {/* Uncomment if you want to add phone number back
      <span className="text-center text-content text-xl font-light block">or</span>
      <h3 className="text-center text-3xl md:text-4xl lg:text-6xl text-gradient font-semibold md:font-bold pt-2 md:py-6">
        <a href={`tel:${phone}`}>{phone}</a>
      </h3> */}
    </main>
  );
}

export default Contact;
