import React from "react";
import Work from "../Components/Work";
import { personalDetails, workDetails, eduDetails } from "../Details";

function About() {
  // Split the about text into individual words
  const aboutWords = personalDetails.about.split(" ");

  return (
    <main className="container mx-auto max-width pt-10 pb-20 ">
      <section>
        <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          About Me
        </h1>
        <div className="text-content py-8 lg:max-w-3xl leading-relaxed">
          {aboutWords.map((word, index) => (
            <React.Fragment key={index}>
              <span className="relative inline-block cursor-pointer">
                <span className="relative z-10 transition-all duration-300 hover:text-sky-500 hover:font-medium">
                  {word}
                </span>
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-sky-500 transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:w-full"></span>
              </span>
              {index < aboutWords.length - 1 ? " " : ""}
            </React.Fragment>
          ))}
        </div>
      </section>
      <section>
        <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Work Experience
        </h1>
        {React.Children.toArray(
          workDetails.map(({ Position, Company, Location, Type, Duration }) => (
            <Work
              position={Position}
              company={Company}
              location={Location}
              type={Type} // Uncomment to show full time flag
              // duration={Duration}  // Uncomment to show dates of projects
            />
          ))
        )}
      </section>
      <section>
        <h1 className="text-2xl pt-10 text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Education
        </h1>
        {React.Children.toArray(
          eduDetails.map(
            ({
              Position,
              Company,
              Location,
              Type,
              Duration,
              CertificationIcon,
            }) => (
              <Work
                position={Position}
                company={Company}
                location={Location}
                type={Type}
                duration={Duration}
                certificationIcon={CertificationIcon}
              />
            )
          )
        )}
      </section>
    </main>
  );
}

export default About;
